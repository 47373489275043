import React from 'react'
import { useTranslation } from 'react-i18next'

import { Services } from 'blocks/Services/Services'
import { RunningLine } from 'components/RunningLine'
import { DefaultFormBlock } from 'blocks/DefaultFormBlock'
import { HelmetFunc } from 'components/PageMetaData'
import { Layout } from 'layout/Layout'
import { Reviews } from 'blocks/Reviews'

const pageMetadata = {
    uk: {
        title: 'Послуги та ціни',
        description:
            'Послуги та ціни на дизайн інтер`єру, архітектурне проектування, декорування інтер`єру та екстер`єру',
    },
    ru: {
        title: 'Услуги и цены',
        description:
            'Услуги и цены на дизайн интерьера, архитектурное проектирование, декорирование интерьера и экстерьера',
    },
    en: {
        title: 'Services and prices',
        description:
            'Services and prices for interior design, architectural design, interior and exterior decoration',
    },
}

const ServicesPage = () => {
    const { t } = useTranslation()

    return (
        <Layout>
            <HelmetFunc data={pageMetadata} />
            <Services />
            <RunningLine>{t('designProject99')}</RunningLine>
            <Reviews />
            <DefaultFormBlock
                withPhoneMobile
                tracking={{
                    conversionType: 'FormServicesPageBottom',
                    eventCategory: 'Bottomform',
                }}
            />
        </Layout>
    )
}

export default ServicesPage
